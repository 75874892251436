import i18n from '@/plugins/i18n'
import { useToasterStore } from '@/stores/toaster'

const { state: toasterStoreState } = useToasterStore()

export const onGenerateEmail = async (data: { email: string; mobiles: any; preferred_contact: string; phone: string; mobile: any }, dialogueVal = Boolean) => {
  const { mobiles, preferred_contact, phone, mobile } = data

  if (phone?.match(/^\d{10}$/)) {
    const generatedEmail = `${phone}${import.meta.env.VITE_AUTO_GENERATE_EMAIL_DOMAIN}`

    // Update email property
    data.email = generatedEmail

    return generatedEmail
  }
  if (preferred_contact?.match(/^\d{10}$/)) {
    const generatedEmail = `${preferred_contact}${import.meta.env.VITE_AUTO_GENERATE_EMAIL_DOMAIN}`

    // Update email property
    data.email = generatedEmail

    return generatedEmail
  }
  if (mobile) {
    const generatedEmail = `${mobile}${import.meta.env.VITE_AUTO_GENERATE_EMAIL_DOMAIN}`

    // Update email property
    data.email = generatedEmail

    return generatedEmail
  }
  if (mobiles?.find(Boolean)?.match(/^\d{10}$/)) {
    const generatedEmail = `${mobiles.find(Boolean)}${import.meta.env.VITE_AUTO_GENERATE_EMAIL_DOMAIN}`

    // Update email property
    data.email = generatedEmail

    return generatedEmail
  }
  else {
    await toasterStoreState.displaySnackbar(i18n.global.t('errors.generate_email_error'), 'error')
  }
  // eslint-disable-next-line no-unused-expressions
  dialogueVal
}

export const getStatisticsInfo = (key: string, data: any, shouldFormat = true) => {
  const value = data?.[key] ? parseFloat(data[key]) : 0// Ensure value is parsed as a float
  const formattedValue = shouldFormat ? `₹ ${Math.abs(value).toLocaleString('en-IN', { minimumFractionDigits: 2 })}` : value // Remove the negative sign and format the number
  const className = value < 0 ? 'text-red' : 'text-green'

  return { value: formattedValue, className: ['totalBalance', 'balance'].includes(key) ? className : '' }
}

export const getDashboardStatisticsInfo = (key: string, data: any, shouldFormat: boolean) => {
  const value = data?.[key] ? parseFloat(data[key]) : 0// Ensure value is parsed as a float

  const formattedValue = shouldFormat ? `₹ ${Math.abs(value).toLocaleString('en-IN', { minimumFractionDigits: 2 })}` : value // Remove the negative sign and format the number
  const className = value < 0 ? 'text-red' : 'text-green'

  return { value: formattedValue, className: ['totalBalance', 'totalMcBooksBalance'].includes(key) ? className : '' }

  return { value: formattedValue, className }
}

export const getMcBookBalance = balance => {
  const value = parseFloat(balance)
  const formattedValue = `₹ ${Math.abs(value || 0).toLocaleString('en-IN', { minimumFractionDigits: 2 })}` // Remove the negative sign and format the number
  const className = value < 0 ? 'text-red' : 'text-green'

  return { value: formattedValue, className }
}

export const getLedgerTypeValue = (key: string) => {
  if (key === 'PAID')
    return 'Paid'
  else
    return 'Received'
}

export const getDueBalance = (value: string) => {
  const formattedValue = Math.abs(value || 0).toLocaleString('en-IN', { minimumFractionDigits: 2 }) // Remove the negative sign and format the number
  const className = value < 0 ? 'text-red' : 'text-green'

  return { value: formattedValue, className }
}

export const getFormattedAmount = (value: string | number) => `₹ ${Math.abs(parseFloat(value || 0)).toLocaleString('en-IN', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
})}`
