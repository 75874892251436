export const boardType = {
  load: 'load',
  truck: 'truck',
}
export const columnType = {
  businessName: 'businessName',
  userPlaceName: 'userPlaceName',
  docsVerified: 'docsVerified',
  status: 'status',
  verified: 'verified',
  landLineNo: 'landLineNo',
  cluster: 'cluster',
}

export const loadBoardSearchFields: any = reactive({
  id: '',
  fullname: '',
  mobiles: '',
  landlines: '',
  email: '',
  designation: '',
  truck_number: '',
  chassis_number: '',
  tyres: '',
  capacity: '',
  engine_number: '',
  primary_fullname: '',
  ownerMobileNo: '',
  driver_fullname: '',
  cluster_place_name: '',
  availableArea: '',
  availableDate: '',
  status: '',
  visibility: '',
  bank_name: '',
  bank_number: '',
  bank_ifsc_code: '',
  bank_branch_address: '',
  bank_type: '',
  bank_pan_number: '',
  is_docs_verified: '',
  confirmationId: '',
  remark: '',
  route_place_name: '',
  description: '',
  paymentType: '',
  reference: '',
  business_name: '',
  trading_name: '',
  place_name: '',
  charges: '',
  routes: '',
  material: '',
  alternative_fullname: '',
  preferred_routes: '',
  available_date: '',
  preferred_contact: '',
  preferred_materials: '',
  alternative_mobiles: '',
  loading_place_name: '',
  unloading_place_name: '',
  load_material_industry: '',
  industry_load_material_industry: '',
  freight_per_ton: '',
  loading_charges: '',
  loading_date: '',
  unloading_charges: '',
  freight_method: '',
  conditions: '',
  is_public: '',
  business_area: '',
  loading_mobiles: '',
  unloading_mobiles: '',
  truckerId: '',
  preferred_route: '',
  locked: '',
  is_lock: '',
  length: '',
  primary_preferred_contact: '',
  driver_preferred_contact: '',
  targeted_route_id: '',
})
