import axios from '@/plugins/axios'

export const MasterService = () => {
  const getMastersData = (params: object, mastersType: string) => {
    return axios.get(`/v1/masters/${mastersType}`, { params })
  }

  const getSmsHeaderDetails = (params: object) => {
    return axios.get('/v1/masters/sms-headers', { params })
  }

  const storeMastersData = (materials: object, mastersType: string) => {
    return axios.post(`/v1/masters/${mastersType}`, materials)
  }

  const deleteMastersData = (deleteObj: { ids: Array<string> }, mastersType: string) => {
    return axios.post(`/v1/masters/${mastersType}/delete`, deleteObj)
  }

  const createTemplate = (templateInfo: object) => {
    return axios.post('/v1/templates', templateInfo)
  }

  const updateMastersData = (id: number, data: object, mastersType: string) => {
    return axios.put(`/v1/masters/${mastersType}/${id}`, data)
  }

  const updateTemplateData = (id: number, data: object) => {
    return axios.put(`/v1/templates/${id}`, data)
  }

  const updateMastersStatus = (req: object, mastersType: string) => {
    return axios.post(`/v1/masters/${mastersType}/status`, req)
  }

  const updateSmsHeaderStatus = (req: object) => {
    return axios.post('/v1/masters/sms-headers/status', req)
  }

  const getMastersById = (id: number, mastersType: string) => {
    return axios.get(`/v1/masters/${mastersType}/${id}`)
  }

  const getTemplateById = (id: number) => {
    return axios.get(`/v1/templates/${id}`)
  }

  const getMastersCommonData = (mastersType: string, isActive: boolean) => {
    return axios.get('/v1/masters', {
      params: {
        modules: mastersType,
        isActive: isActive ? 1 : 0,
      },
    })
  }

  const getMastersCondition = (isActive = false, mastersType: string, type: string, ids: []) => {
    return axios.get('/v1/masters/get-conditions', {
      params: {
        isActive: isActive ? 1 : 0,
        modules: mastersType,
        type,
        ids: JSON.stringify(ids),
      },
    })
  }

  const addMastersData = (mastersType: string, mastersInfo: object) => {
    return axios.post(`/v1/masters/${mastersType}/`, mastersInfo)
  }

  const getUsers = (data: object) => {
    return axios.get('/v1/masters/get-users', { params: data })
  }

  const deleteMastersMcData = (mastersType: string, data: object) => {
    return axios.post(`/v1/masters/${mastersType}/delete`, data)
  }

  const AddMastersData = (materials: object, mastersType: string) => {
    return axios.post(`/v1/masters/${mastersType}/`, materials, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  const editMastersData = (id: number, data: object, mastersType: string) => {
    data._method = 'PUT'

    return axios.post(`/v1/masters/${mastersType}/${id}`, data, {

      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  const getSmsTemplateInfo = (params: object) => {
    return axios.get('/v1/templates', { params })
  }

  const getSmsHeader = (params: object) => {
    return axios.get('/v1/masters/sms-headers?isActive=1', { params })
  }

  const getUsersBusinessNames = (usersType: string, params: object) => {
    return axios.get(`/v1/masters/users/business-names/${usersType}`, { params })
  }

  return { AddMastersData, createTemplate, getSmsHeaderDetails, getSmsHeader, getSmsTemplateInfo, updateSmsHeaderStatus, updateTemplateData, editMastersData, getMastersData, getUsers, addMastersData, storeMastersData, getTemplateById, deleteMastersMcData, deleteMastersData, updateMastersData, updateMastersStatus, getMastersById, getMastersCommonData, getMastersCondition, getUsersBusinessNames }
}
