import i18n from '@/plugins/i18n'

export const hasTypeInfo = {
  loaderInfo: 'loader-info',
  truckerInfo: 'trucker-info',
}

export const smsSentReport = [
  { name: 'Date & Time', value: 'date' },
  { name: 'Mobile No.', value: 'mobile' },
  { name: 'Template ID', value: 'template_id' },
  { name: 'Template', value: 'template' },
  { name: 'SMS Status', value: 'status' },
]

export const confirmationType = {
  pending: 'Pending',
  booked: 'Booked',
  Booked: 'Confirmed',
  confirmed: 'Confirmed',
  cancelled: 'Cancelled',
  completed: 'Completed',
}

export const confirmationListKeys = {
  loadingPoints: 'loadingPoints',
  unloadingPoints: 'unloadingPoints',
  materials: 'materials',
  conditions: 'conditions',
  preferredRoutes: 'preferredRoutes',
  confirmation: 'confirmation',
  page: 'page',
  limit: 'limit',
  totalFreight: i18n.global.t('Total Freight'),
  freight: i18n.global.t('Freight'),
  crossing: i18n.global.t('Crossing'),
  freightMethod: i18n.global.t('Freight Method'),
  quantity: i18n.global.t('Quantity'),
  commission: i18n.global.t('Commission'),
  totalAmount: i18n.global.t('Total Amount'),
  balanceAmountTitle: i18n.global.t('Balance Amount'),
  document: {
    directory: 'ConfirmationDocuments',
    section: 'confirmation_documents',
    podSection: 'pod_documents',
  },
  NA: i18n.global.t('N/A'),
  booked: 'Booked',
  sms: 'sms',
  whatsapp: 'whatsapp',
  noPaymentLink: 'Your account with this user is now fully settled. There is no outstanding balance.',
  preferredContact: 'preferredContact',
  altContactMobile: 'altContactMobile',
  ownerContact: 'ownerContact',
  driverMobile: 'driverMobile',
  notes: 'notes',
  freight_amount: i18n.global.t('Freight'),
  pr_amount: i18n.global.t('PR'),
  or_amount: i18n.global.t('OR'),
  freightAmount: 'freightAmount',
  balanceAmount: 'balanceAmount',
  pending: 'Pending',
}

export const confirmationTableOptions = reactive({
  page: 1,
  limit: 10,
})

export const confirmationStatus = {
  Pending: 'Pending',
  Booked: 'Confirmed',
  Cancelled: 'Cancelled',
  Completed: 'Completed',
  Confirmed: 'Confirmed',
}

export const confirmationSearchStatus = [
  { text: 'Confirmed', value: 'Confirmed' },
  { text: 'Pending', value: 'Pending' },
  { text: 'Completed', value: 'Completed' },
  { text: 'Cancelled', value: 'Cancelled' },
  { text: 'All', value: '' },
]

export const podSearchStatus = [
  { text: 'Confirmed', value: 'Confirmed' },
  { text: 'Completed', value: 'Completed' },
  { text: 'All', value: '' },
]

export const confirmationSaveType = {
  freight: 'freight',
  pod: 'pod',
  crossing: 'crossing',
  update: 'update',
}

export const paymentMethod = {
  cash: 'CASH',
  netBanking: 'NETBANKING',
  razorpay: 'RAZORPAY',
  mcBook: 'MC_BOOK',
}

export const paymentType = {
  cash: 'Cash',
  netBanking: 'Net Banking',
  mcBook: 'MC Books',
}

export const documentsTypesOptions = reactive({
  page: 1,
  results: 10,
  sortField: '',
  sortOrder: '',
  search: true,
  type: '',
})

export const getModuleTitle = [
  { title: 'Loader' },
  { title: 'Trucker' },
  { title: 'Driver' },
]

export const getLoadingTitle = [
  { title: 'Loading Point', value: 'loadingPointMessage' },
]

export const getUnloadingTitle = [
  { title: 'Unloading Point' },
]

export const userType = [
  { text: i18n.global.t('loader.type'), value: 'loader' },
  { text: i18n.global.t('trucker.type'), value: 'trucker' },
]

export const confirmationsSearchFields = reactive({
  confirmationId: '',
  confirmationDate: '',
  loadId: '',
  loaderId: '',
  loaderBusinessName: '',
  offerBusinessName: '',
  loaderTradingName: '',
  offerTradingName: '',
  loaderPrimaryName: '',
  loaderPrimaryNo: '',
  loaderAltName: '',
  loaderAltNo: '',
  loadingPoints: null,
  unloadingPoints: null,
  loadingCluster: null,
  capacity: null,
  industry: null,
  products: null,
  offerOwnerPrimaryName: '',
  offerCluster: null,
  offerOwnerPrimaryNo: '',
  driverName: '',
  driverNo: '',
  truckNo: '',
  tyres: null,
  freightMethod: null,
  remark: null,
  status: null,
  offerId: '',
  truckerId: '',
  targetedRouteId: '',
})

export const resetConfirmationsSearchFields = ({ ...confirmationsSearchFields })

export const podSearchFields = reactive({
  confirmationId: '',
  confirmationDate: '',
  loadId: '',
  loaderId: '',
  loaderBusinessName: '',
  offerBusinessName: '',
  loaderTradingName: '',
  offerTradingName: '',
  loaderPrimaryName: '',
  loaderPrimaryNo: '',
  loaderAltName: '',
  loaderAltNo: '',
  loadingPoints: null,
  unloadingPoints: null,
  loadingCluster: null,
  capacity: null,
  industry: null,
  products: null,
  offerOwnerPrimaryName: '',
  offerCluster: null,
  offerOwnerPrimaryNo: '',
  driverName: '',
  driverNo: '',
  truckNo: '',
  tyres: null,
  freightMethod: null,
  remark: null,
  status: null,
  offerId: '',
  truckerId: '',
})

export const resetPodSearchFields = ({ ...podSearchFields })

export const SMS_TYPE = {
  CONFIRMATION_LINK: 'confirmationLink',
  BANK_DETAILS: 'bankDetails',
  PAYMENT_LINK: 'paymentLink',
}

export const invoiceListHeader = [
  { title: 'Invoice No.', key: 'invoiceNo', sortable: false, width: '5%' },
  { title: 'Document Type', key: 'invoiceType', sortable: false, width: '15%' },
  { title: 'Loading Point', key: 'loadingPoint', sortable: false, width: '15%' },
  { title: 'Unloading Point', key: 'unLoadingPoint', sortable: false, width: '10%' },
  { title: 'Materials/Products', key: 'material', sortable: false, width: '10%' },
  { title: 'Capacity', key: 'capacity', sortable: false, width: '10%' },
  { title: 'Freight', key: 'totalFreight', sortable: false, width: '10%' },
  { title: 'Date', key: 'invoiceDate', sortable: false, width: '10%' },
  { title: 'Actions', key: 'actions', sortable: false, width: '5%' },
]
